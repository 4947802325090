import React, { useState, useEffect } from 'react';
import './new.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { requestOTPAction, loginAction } from "../redux/actions/loginAction";
import { allValidations } from "../utils/formValidations";
import { showFormErrors } from "../utils/commonFunctions";
import { CustomInputMobileNumber } from '../shared/AllInput';

export default function LoginSidebar({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [data, setData] = useState({
    countryCode: "+91",
    mobile: "",
    errorMessage: "",
  });
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);

  // Countdown timer for Resend OTP
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "mobile") {
      value = value.replace(/^(\+91\s?)/, ""); // Remove +91 if present
    }
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleResendOtp = async () => {
    if (timer === 0) {
      await dispatch(requestOTPAction(data, setLoading, setOtpSent));
      setTimer(30);
    }
  };

  // const handleChangeNumber = () => {
  //   setOtpSent(false);
  //   setOtp("");
  //   setData((prev) => ({ ...prev, mobile: "", errorMessage: "" }));
  //   setTimer(0);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      try {
        if (otpSent) {
          await dispatch(loginAction({ ...data, otpCode: otp }, setLoading, onClose, navigate));
        } else {
          await handleResendOtp();
        }
      } catch (error) {
        console.error("Login or OTP resend error:", error);
      }
    }
  };

  return (
    <div className={`login__sidebar-overlay ${isOpen ? 'open' : ''}`}>
      <div className="login__sidebar">
        <button className="login__close-btn" onClick={onClose}>x</button>
        <div className="login__content">
          <h3 className='login__content__heading'>
            {otpSent
              ? `We have sent a verification code to ${data.countryCode} ${data.mobile}`
              : "India's #1 Scan App where vehicle owners meet"}
          </h3>
          <form onSubmit={handleSubmit}>
            {!otpSent ? (
              <input
                type="text"
                name="mobile"
                // value={data.mobile}
                value={`+91 ${data.mobile}`}
                // value={`${data.countryCode} ${data.mobile}`}
                onChange={handleChange}
                placeholder="Enter Mobile Number"
                className="login__otp-input"
                required
              />
              // <CustomInputMobileNumber
              //   col="12"
              //   data={data}
              //   onChange={handleChange}
              //   name="mobile"
              //   label="Mobile Number"
              //   required
              //   extraClassName={"login__otp-input"}
              //   //   className="login__otp-input"

              //   errorMessage={data.errorMessage}
              // />
            ) : (
              <>
                <input
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  placeholder="Enter OTP"
                  className="login__otp-input"
                  maxLength={6}
                  required
                />
                <div className="login__resend">
                  <button
                    type="button"
                    onClick={handleResendOtp}
                    className="login__resend-btn resend-button-styled"  // Use a new CSS class for custom styling

                    disabled={loading || timer > 0}
                  // className="login__resend-btn"
                  >
                    Resend OTP {timer > 0 ? `(${timer}s)` : ""}
                  </button>
                  {/* <button
                    type="button"
                    onClick={handleChangeNumber}
                    className="login__change-number-btn"
                  >
                    Change number
                  </button> */}
                </div>
              </>
            )}
            <button type="submit" className="login__verify-btn" disabled={loading}>
              {otpSent ? "Verify OTP" : "Send OTP"}
            </button>
          </form>
          <div className="login__footer">
            <span className='login__footer__text'>By continuing, you agree to our</span>
            <br />
            <Link to="/terms-and-condition" className="login__footer-link">Terms of Service</Link>
            <Link to="/privacy-policy" className="login__footer-link">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
