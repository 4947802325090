import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import vehicleReducer from "./vehicleReducer.js";

export default combineReducers({
  toast: toastReducer,
  loader: loaderReducer,
  vehicle: vehicleReducer,
});
