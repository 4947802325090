export default class Constants {
  static END_POINT = {
    LOGIN: "/user/login",
    REQUEST_OTP: "/user/request-otp",

    GET_VEHICLES: "/user/vehicle",

    QR_DETAIL: "/qr-details",
    EMERGENCY_ALERTS: "/emergency-alert",
    USER_ALERT: "/user-alert",
  };
}
