import React from 'react';
import VehicleCard from '../../components/VehicleCard';
import PlanCard from '../../components/PlanCard';
import './index.css'
import { CreditCard, Download } from 'lucide-react';

export default function Dashboard() {
    return (
        <div className="dashboard">
            <div className="dashboard__grid">
                {/* Left Column */}
                <div className="dashboard__section">
                    <h2 className="dashboard__title left__dashboard_title_section">My vehicles</h2>
                    <div>
                        <VehicleCard
                            name="Ford Endeavour"
                            price="15"
                            image="https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?auto=format&fit=crop&q=80&w=300"
                            isActive={true}
                        />
                        <VehicleCard
                            name="i20"
                            price="90"
                            image="https://images.unsplash.com/photo-1541899481282-d53bffe3c35d?auto=format&fit=crop&q=80&w=300"
                        />
                    </div>
                </div>
                {/* Right Column */}
                <div className="dashboard__section">
                    <div className="dashboard__header">
                        <h2 className="dashboard__title">My plan</h2>
                        <button className="dashboard__download-btn">
                            <Download size={20} />
                            <span>Download Invoice</span>
                        </button>
                    </div>

                    {/* Current Plan */}
                    <div className="current-plan">
                        <div className="current-plan__header">
                            <div>
                                <h3 className="current-plan__title">Monthly</h3>
                                <p className="current-plan__date">Next Billing Date - 27/04/2024</p>
                            </div>
                            <p className="current-plan__price">₹15</p>
                        </div>
                        <ul className="current-plan__features">
                            <li className="current-plan__feature">• Instant Safety Alerts</li>
                            <li className="current-plan__feature">• In App Calling Feature</li>
                            <li className="current-plan__feature">• Effortless Vehicle Registration</li>
                        </ul>
                        <button className="current-plan__cancel"><u>Cancel plan</u></button>
                    </div>

                    {/* Membership Plans */}
                    <div className="dashboard__section">
                        <h3 className="dashboard__title">Membership plans</h3>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                            <PlanCard title="Half Yearly" price="90" />
                            <PlanCard title="Yearly" price="180" save={true} />
                        </div>
                    </div>

                    {/* Billing Details */}
                    <div className="billing">
                        <h3 className="billing__title">Billing Details</h3>
                        <div className="billing__card">
                            <div className="billing__header">
                                <div>
                                    <p className="billing__plan-name">Monthly Plan</p>
                                    <p className="billing__date">Tuesday, 26/06/2023</p>
                                </div>
                                <p className="billing__price">₹15</p>
                            </div>
                            <div className="billing__payment">
                                <CreditCard />
                                <span className="billing__card-number">•••• •••• •••• 5896</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}