import api from "../../services/api";
import Constants from "../../services/constants";
import { authenticate, isAuthenticated } from "../../services/auth";
import { types } from "../types/types";
import { showToast } from "./toastAction";

export const loginAction =
  (data, setLoading, onclose, navigate) => async (dispatch) => {
    setLoading(true);
    const res = await api("post", Constants.END_POINT.LOGIN, data);
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.USER_DETAIL,
          payload: res.data,
        });
        authenticate(res.data.token, () => {
          if (isAuthenticated()) {
            onclose(true);
            navigate("/dashboard");
          }
        });
      }
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
  };

export const requestOTPAction =
  (data, setLoading, setOtpSent) => async (dispatch) => {
    setLoading(true);
    const res = await api("post", Constants.END_POINT.REQUEST_OTP, data);
    if (res.success) {
      setOtpSent(true);
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
  };
