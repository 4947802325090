import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction, requestOTPAction } from "../../redux/actions/loginAction";
import { showFormErrors } from "../../utils/commonFunctions";
import { CustomForm, CustomInputMobileNumber } from "../../shared/AllInput";
import { allValidations } from "../../utils/formValidations";
import PrimaryButton from "../../shared/Button/PrimaryButton";
import CustomCard from "../../shared/Card/CustomCard";
import Logo from "../../assets/img/logo.png";
import Background from "../../shared/Background/Background";
import { InputOtp } from 'primereact/inputotp';
import { Button } from "primereact/button";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            navigate("/dashboard");
        }
    }, [navigate]);

    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState("");
    const [data, setData] = useState({
        countryCode: "+91",
        mobile: "",
    });

    const [timer, setTimer] = useState(0); // Timer state for Resend OTP

    // Countdown effect for timer
    useEffect(() => {
        if (timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        }
    }, [timer]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleOtpChange = (e) => {
        setOtp(e.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            try {
                if (otpSent) {
                    await dispatch(loginAction({ ...data, otpCode: otp }, setLoading, navigate));
                } else {
                    await handleResendOtp();
                }
            } catch (error) {
                console.error("Login or OTP resend error:", error);
            }
        }
    };

    const handleChangeNumber = () => {
        setOtpSent(false);
        setOtp("");
        setData((prev) => ({ ...prev, mobile: "", errorMessage: "" }));
        setTimer(0);
    };

    const handleResendOtp = async () => {
        if (timer === 0) {
            await dispatch(requestOTPAction(data, setLoading, setOtpSent));
            setTimer(30);
        }
    };

    return (
        <Background>
            <div className="z-1 relative grid mt-5">
                <div className="col-4 md:col-6 mx-auto">
                    <CustomCard className="light" backable={false}>
                        <div className="text-center">
                            <img src={Logo} alt="logo" style={{ width: "300px" }} />
                        </div>
                        <h2 className="text-4xl mb-3">Login</h2>
                        <CustomForm>
                            <form onSubmit={handleSubmit} className="col-12">
                                {!otpSent ? (
                                    <CustomInputMobileNumber
                                        col="12"
                                        data={data}
                                        onChange={handleChange}
                                        name="mobile"
                                        label="Mobile Number"
                                        required
                                        errorMessage={data.errorMessage}
                                    />
                                ) : (
                                    <>
                                        <div className="p-field col-6">
                                            <label htmlFor="otp" className="block mb-2">Enter OTP</label>
                                            <InputOtp
                                                id="otp"
                                                value={otp}
                                                onChange={handleOtpChange}
                                                className="w-full"
                                                maxLength={6}
                                                required
                                            />
                                        </div>
                                        <div className="flex justify-between mt-2">
                                            <div className="col-6">
                                                <Button
                                                    label={`Resend OTP${timer > 0 ? ` (${timer}s)` : ""}`}
                                                    className="send-btn btn text-white bg-yellow main-btn"
                                                    onClick={handleResendOtp}
                                                    disabled={loading || timer > 0} // Disable button while loading or timer > 0
                                                />
                                                <button
                                                    type="button"
                                                    onClick={handleChangeNumber}
                                                    className="p-link"
                                                >
                                                    Click here to change number
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <PrimaryButton
                                    extraClassName="mt-4"
                                    label={otpSent ? "Verify OTP" : "Send OTP"}
                                    loading={loading}
                                    disabled={loading}
                                    type="submit"
                                />
                            </form>
                        </CustomForm>
                    </CustomCard>
                </div>
            </div>
        </Background>
    );
};

export default Login;
