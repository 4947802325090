import React, { useRef } from 'react';
import { User } from 'lucide-react';
import { Link } from 'react-router-dom';
import './index.css';
import LogoImg from "../../assets/images/logo.png";
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

export default function AuthNavbar() {

    const menuRight = useRef(null);

    const items = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Refresh',
                    icon: 'pi pi-refresh'
                },
                {
                    label: 'Export',
                    icon: 'pi pi-upload'
                }
            ]
        }
    ];

    return (
        <nav className="authnavbar">
            <div className="navbar__container">
                <div className="navbar__content">
                    <Link to="/" className="navbar__logo">
                        <img src={LogoImg} alt='' width={150} />
                    </Link>

                    <div className="navbar__user">
                        <span>John Bride</span>
                        <div className="navbar__avatar">
                            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                            <Button label="Show Right" icon="pi pi-align-right" className="mr-2" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
                            <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                            <User onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup className="navbar__avatar-icon" size={20} />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}