import React from 'react';
import './index.css';

export default function VehicleCard({ name, price, image, isActive }) {
    return (
        <div className={`vehicle-card ${isActive ? 'vehicle-card--active' : 'vehicle-card--inactive'}`}>
            <div className="vehicle-card__content">
                <img src={image} alt={name} className="vehicle-card__image" />
                <div>
                    <h3 className="vehicle-card__name">{name}</h3>
                    <p className="vehicle-card__price">₹{price}</p>
                </div>
            </div>
        </div>
    );
}