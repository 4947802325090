import React, { useEffect, useState } from "react";
import Logo from "../assets/img/logo.png";
import { Link } from "react-scroll";

export default function TopHeader({ openSidebar }) {

  const [sticky, setSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header id="home">
        <nav
          className={`navbar navbar-default navbar-fixed white  bootsnav ${sticky ? "sticky" : "no-background"
            }`}
        >
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                onClick={toggleMenu}
              >
                <i className="fa fa-bars text-white"></i>
              </button>
              <Link className="navbar-brand" to="/">
                <img
                  src={Logo}
                  className="logo m-0 logo-display "
                  alt="Logo"
                  width="150"
                />
                <img
                  src={Logo}
                  className="logo m-0 logo-scrolled "
                  alt="Logo"
                  width="150"
                />
              </Link>
            </div>

            <div
              className={`header-menu ${isOpen ? "open-menu" : "close-menu"}`}
              id="navbar-menu"
            >
              <ul
                className="nav navbar-nav navbar-right"
                data-in="#"
                data-out="#"
              >
                <li>
                  <Link
                    href="#home"
                    className="smooth-menu"
                    data-toggle="dropdown"
                    to="home" smooth={true} duration={500}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="smooth-menu" to="how-its-works" smooth={true} duration={500}>
                    How it works
                  </Link>
                </li>
                {/* <li>
                  <Link className="smooth-menu" to="portfolio" smooth={true} duration={500}>
                    Portfolio
                  </Link>
                </li> */}

                <li>
                  <Link className="smooth-menu" to="plans" smooth={true} duration={500}>
                    Plans
                  </Link>
                </li>

                <li>
                  <Link className="smooth-menu btn_showborder" to="contact" smooth={true} duration={500}>
                    contact
                  </Link>
                </li>

                <li>
                  <Link className="smooth-menu btn_showborder ml-2" to="login" smooth={true} duration={500} onClick={openSidebar}>
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
