import React from 'react';
import './index.css';

export default function PlanCard({ title, price, save }) {
    return (
        <div className="plan-card">
            {/* {save && (
                <div className="plan-card__save-badge">
                    Save 5%
                </div>
            )} */}
            <h3 className="plan-card__title">{title}</h3>
            <p className="plan-card__price">₹{price}</p>
            <button className="plan-card__button">
                Upgrade
            </button>
        </div>
    );
}